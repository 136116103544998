import React from 'react'

import './Button.css'

const Button = (props) => {

  return (
    <div className="Button">
      <button {...props}>{props.children}</button>
    </div>
  );
};

export default Button;
