import { observer } from "mobx-react-lite";
import React from "react";
import AppState, { POPUPS, SCREENS } from "../../js/AppState";

import ScaleManager from 'react-sdk/ScaleManager';
import ScreenHome from "../ScreenHome/ScreenHome";
import ScreenLoading from "../ScreenLoading/ScreenLoading";

import JistiModeMalentendantPopup from "../JistiModeMalentendantPopup/JistiModeMalentendantPopup";
import ScreenEndGame from "../ScreenEndGame/ScreenEndGame";
import ScreenGame from "../ScreenGame/ScreenGame";
import Notifs from "../shared/Notifs/Notifs";
import './App.css';


const App = () => {
  return (
    <div className="App" style={ScaleManager.style}>
      {AppState.screen === SCREENS.LOADING && <ScreenLoading />}
      {AppState.screen === SCREENS.HOME && <ScreenHome />}
      {AppState.screen === SCREENS.END_GAME && <ScreenEndGame />}

      <ScreenGame />
      <Notifs />

      {AppState.popup === POPUPS.MODE_MALENTENDANT_ACTIVATED && <JistiModeMalentendantPopup />}
    </div>
  )
}

export default observer(App);
