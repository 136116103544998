import React, { useEffect, useRef } from 'react'
import AppState, { POPUPS, SCREENS } from '../../js/AppState';
import { observer } from 'mobx-react-lite';

import './ScreenGame.css';
import JitsiFooter from '../shared/JitsiFooter/JitsiFooter';
import GameHeader from './GameHeader/GameHeader';
import SettingsPopup from '../SettingsPopup/SettingsPopup';
import FakeGame from './FakeGame/FakeGame';

const ScreenGame = () => {

  let isvisible = AppState.screen === SCREENS.GAME

  useEffect(() => {
    let params = {}
    if(window.lime){
      console.log("LOADING GAME !!!")
      window.lime.embed ("main", "game-content", 720, 1000, { parameters: params });
    }
    else {
      console.log("lime not available")
    }
  }, []);

  useEffect(() => {

    if(isvisible && AppState.gameReady) {
      if(AppState.lp_api.registeredFunctions.startGame) {
        AppState.lp_api.registeredFunctions.startGame(false)
      }
    }

  }, [isvisible, AppState.gameReady]);

  return (
    <div className={"ScreenGame" + (isvisible ? " visible" : "")}>
      {isvisible && <GameHeader />}

      <div id="game-content">
        {!window.lime && <FakeGame />}
      </div>

      {AppState.popup === POPUPS.SETTINGS && <SettingsPopup />}
      {isvisible && !AppState.nojitsi &&<JitsiFooter />}
    </div>
  );
};

export default observer(ScreenGame)
