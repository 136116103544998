import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import Texts from 'react-sdk/Texts';
import AppState, { SCREENS } from '../../js/AppState';

import { useEffect } from 'react';
import logo from "../../images/logo.png";
import Button from '../shared/Button/Button';
import './ScreenHome.css';
import { useRef } from 'react';

const ScreenHome = () => {

  const [code, setCode] = useState("");
  const [pseudo, setPseudo] = useState("");


  const [step, setStep] = useState(1);

  const [codeError, setCodeError] = useState(null);
  const [pseudoError, setPseudoError] = useState(null);

  let nb_tries = useRef(0)

  useEffect(() => {
    if (pseudo) {
      if(pseudo.length > window.CONFIG.pseudo_max_length) {
        setPseudo(pseudo.slice(0, -1))
      }
    } else {
      setPseudo("")
    }
  }, [pseudo])

  useEffect(() => {
    if (!code) {
      setCode("")
    }
  }, [pseudo])


  function handleSubmitCode(e) {

    e.preventDefault()
    setCodeError(null)

    if(code === "") {
      setCodeError(Texts.get("home-code-empty-error"))
      return
    }
    // NOTE cette fonction nous connecte au meeting aussi !!!!
    connectToMeeting()
  }

  function connectToMeeting() {

    nb_tries.current++

    AppState.vertxApi.userMeeting.findOpenedWithCode("MiniGames", code, (result) => {
      if(result.status) {
        AppState.vertxApi.gameSettings.set({meetingCode : code})
        setStep(2)
      }
      else {
        if(nb_tries.current <= 3) {
          connectToMeeting()
        }
        else {
          // ICI il faut retenter la connexion 2 autres fois
          setCodeError(Texts.get(result.statusMessage))
          console.log("findOpenedWithCode", result.statusMessage)
        }
      }
    })
  }



  function handleSubmitPseudo(e) {
    e.preventDefault()


    if(pseudo === "") {
      setPseudoError(Texts.get("home-pseudo-empty-error"))

      return
    }

    setPseudoError(null)
    AppState.vertxApi.client.UserUpdatePseudo(pseudo, (success, message, data) => {
      if(success) {
        AppState.vertxApi.user.getConnectedUser().pseudo = pseudo
        AppState.vertxApi.gameSettings.set({autoReco : true, userPseudo : pseudo})
        AppState.setScreen(SCREENS.GAME)
      }
      else {
        console.log("error UserUpdatePseudo", message)
        setPseudoError(message)
      }
    })

  }


  function handleClick(e) {
    AppState.noSleep.enable()
  }


  return (
    <div className="ScreenHome">

      <h2>{Texts.get("home-title")}</h2>
      <h1>
        <img src={logo} alt="" />
      </h1>
      <div className="info microphone-info">
        {Texts.get("microphone-info")}
      </div>


      {step === 1 &&
      <>

        <form className="meeting-form" onSubmit={handleSubmitCode}>
          <input type="text"
          value={code}
          onChange={e => setCode(e.target.value)}
          placeholder={Texts.get("home-code-input-placeholder")} />

          <Button onClick={handleClick}>{Texts.get("home-code-play-button")}</Button>

          {codeError && <div className="error">{codeError}</div>}
        </form>

        <div className="no-code">
          <p>{Texts.get("home-no-code")}</p>
          <a href={Texts.get("home-button-acheter-link")} target="_blank">
            <Button>{Texts.get("home-button-acheter")}</Button>
          </a>
        </div>

      </>
      }


      {step === 2 &&
      <>

      {/* <div className='info'>{Texts.get("home-code-info")}</div> */}
      <form className="meeting-form" onSubmit={handleSubmitPseudo}>
        <input autoFocus type="text" value={pseudo} onChange={e => setPseudo(e.target.value)} placeholder={Texts.get("home-pseudo-placeholder")} />
        <Button>{Texts.get("home-pseudo-valid-button")}</Button>
        {pseudoError && <div className="error">{pseudoError}</div>}
      </form>

      <div className="no-code-2">
          <p>{Texts.get("home-no-code")}</p>
          <a href={Texts.get("home-button-acheter-link")} target="_blank">
            <Button>{Texts.get("home-button-acheter")}</Button>
          </a>
        </div>

      {/*</div>*/}

      </>
      }


    </div>
  );
};

export default  observer(ScreenHome)
