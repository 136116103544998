import React, { useEffect } from 'react'
import AppState from '../../../js/AppState';
import Button from '../Button/Button';

import './Popup.css'

const Popup = ({title, children, className="", onClose}) => {

  function handleClose() {
    if(onClose) onClose()
  }

  useEffect(() => {
    const handleEscape = e => { if(e.code === 'Escape')  onClose() }
    window.addEventListener('keydown', handleEscape)

    return () => { window.removeEventListener('keydown', handleEscape) }
  }, []);

  return (
    <div className="Popup-overlay" onClick={e => e.stopPropagation()}>
      <div className={"Popup " + className} onClick={e => e.stopPropagation()}>
        {onClose && <div className="Popup-close"><Button onClick={onClose}>&times;</Button></div>}

        {title && <h3 className="Popup-title">{title}</h3>}

        <div className="Popup-content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Popup;