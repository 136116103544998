import { observer } from 'mobx-react-lite';
import React from 'react';
import { secsToMinSecs, secsToTime } from 'react-sdk/utils';
import AppState, { POPUPS } from '../../../js/AppState';
import Button from '../../shared/Button/Button';

import './GameHeader.css';
import settings_icon from "../../../images/settings-icon.png"
import fullscreen_icon from "../../../images/fullscreen-icon.png"
import Texts from 'react-sdk/Texts';
const GameHeader = () => {
  let time = secsToTime(AppState.globalTimer)


  return (
    <div className="GameHeader">
      {(AppState.globalTimervisible) &&
      <div className="global-timer">
        <div className="global-timer-title">{Texts.get("game-header-timer-title")}</div>
        <div className="global-timer-time">
          {time.min.toString().padStart(2, "0")}:
          {Math.floor(time.secs).toString().padStart(2, "0")}
        </div>
      </div>}

      <div className="spacer"/>

      <Button onClick={() => AppState.setPopup(POPUPS.SETTINGS)}>
        <img src={settings_icon} />
      </Button>

      <Button onClick={() => AppState.lp_api.toggleFullscreen()}>
        <img src={fullscreen_icon} />
      </Button>
    </div>
  );
};

export default observer(GameHeader)
