import React from 'react'
import Texts from 'react-sdk/Texts';
import AppState, { SCREENS } from '../../js/AppState';
import Button from '../shared/Button/Button';
import JitsiFooter from '../shared/JitsiFooter/JitsiFooter';

import logo from "../../images/logo.png"

import './ScreenEndGame.css'
import { makeId, secsToTime } from 'react-sdk/utils';

const ScreenEndGame = () => {
  let {type, elapsedTimeSec } = AppState.lp_api.args.vars

  console.log("type", type)

  let time = secsToTime(elapsedTimeSec, false)
  return (
    <div className={"ScreenEndGame " + type}>
      <div className="ScreenEndGame-content">

      <div className="logo">
        <img src={logo} alt="" />
      </div>

        {type === "end_timer" &&
        <>
          <h1>{Texts.get("ecran-fin-defaite")}</h1>
          <h2>{Texts.get("ecran-fin-timeout")}</h2>
          <div className='ScreenEndGame-text' dangerouslySetInnerHTML={{__html: Texts.get("ecran-fin-timeout-text")}} />
          <Button onClick={() => AppState.setScreen(SCREENS.GAME)}>{Texts.get("ecran-fin-timeout-btn")}</Button>
        </>}

        {(type === "end_good" || type === "end_bad" )&&
        <>
          {type === "end_good" && <h1>{Texts.get("ecran-fin-victoire")}</h1>}
          {type === "end_bad" && <h1>{Texts.get("ecran-fin-defaite")}</h1>}

          <div className="timer">
            <div className="timer-title">{Texts.get("ecran-fin-total-time")}</div>
            <h1 className="timer-time">
              {Math.floor(time.min).toString().padStart(2, "0")}:{Math.floor(time.secs).toString().padStart(2, "0")}</h1>
          </div>

          {type === "end_bad" &&
          <div className='ScreenEndGame-text' dangerouslySetInnerHTML={{__html: Texts.get("ecran-fin-defaite-text")}} />}

          {type === "end_good" &&
          <div className='ScreenEndGame-text' dangerouslySetInnerHTML={{__html: Texts.get("ecran-fin-victory-text")}} />}
            <a target="_blank" href={Texts.get("ecran-fin-btn-know-more-link")}>
              <Button>
                {Texts.get("ecran-fin-btn-know-more")}
              </Button>
            </a>
          <Button className="ghost" onClick={() => AppState.setScreen(SCREENS.GAME)}>
            {Texts.get("ecran-fin-btn-retour-lab")}
          </Button>
        </>}


      </div>

      <JitsiFooter />
    </div>
  );
};

export default ScreenEndGame;
