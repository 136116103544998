import AppState, { POPUPS, SCREENS } from "./AppState"
import Jitsi from "./Jitsi"

export default class LP_api {
  onFullscreen = false
  args = null
  registeredFunctions = {}

  getVars() {
    if(this.args.vars) return this.args.vars
    return {}
  }
  openSettings(onClose) {
    this.args = {onClose}
    AppState.setPopup(POPUPS.SETTINGS)
  }

  endGame(vars){
    this.args = {vars}

    AppState.setScreen(SCREENS.END_GAME)
  }
  getVertxApi(){
    return AppState.vertxApi
  }


  callFunction(name, param) {
    let ret = null


    switch(name) {
      case "muteJitsi": Jitsi.mute(true); break;
      case "unmuteJitsi": Jitsi.unmute(true); break;
      case "toggleMuteJitsi": Jitsi.toggleMute(true); break;
      case "updateGlobalTimer": AppState.globalTimer = param; break;
      case "showGlobalTimer": AppState.globalTimervisible = param; break;
      case "getPlayerColors": ret = window.CONFIG.jitsi.colors; break;
      case "getPlayerTextColors": ret = window.CONFIG.jitsi.textColors; break;
      case "updatePlayersState": Jitsi.updatePlayersState(param); break;
      case "refreshPage": AppState.vertxApi.gameSettings.set({autoReco : param}); window.location.reload(); break;
      case "signalGameReady": AppState.gameReady = true; break;
    }

    return ret
  }

  toggleFullscreen() {
    if(this.onFullscreen){
      deactivateFullscreen()
      this.onFullscreen = false
    }
    else {
      activateFullscreen()
      this.onFullscreen = true
    }
  }

  openVideo(id, onClose) {
    this.args = {id, onClose}
    AppState.setPopup(POPUPS.VIDEO)
  }

  registerVirtualKeyboardEvent(callback) {
    if ('virtualKeyboard' in navigator) navigator.virtualKeyboard.addEventListener('geometrychange', callback)
  }

  registerFunction(name, func) {
    this.registeredFunctions[name] = func
  }



}



function activateFullscreen() {
  var isFullscreenAvailable = document.fullscreenEnabled;
  if(!isFullscreenAvailable) {
    console.log("fullscreen not authorized")
    return
  }
  window.document.documentElement.requestFullscreen()
}

function deactivateFullscreen() {
  var isFullscreenAvailable = document.fullscreenEnabled;
  if(!isFullscreenAvailable) {
    console.log("fullscreen not authorized")
    return
  }
  window.document.exitFullscreen()
}