import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import AppState from '../../../js/AppState';

import './FakeGame.css'

const FakeGame = () => {

  const [gameStarted, setGameStarted] = useState(false);

  var isMuted = false

  function onCloseSettings() {
    console.log("FROM GAME settings closed")
  }
  function onSettingsChanged(newsettings) {
    console.log("FROM GAME settings changed", newsettings)
    window.isMuted = newsettings.isMuted
  }

  function onVideoClose() {
    console.log("FROM GAME video closed")
  }



  useEffect(() => {

    console.log("USING FAKE GAME")
    window.LP_API.registerFunction("startGame", () => {
      setGameStarted(true)
      // setTimeout(() => getStates((states) =>  window.LP_API.callFunction('updatePlayersState', states)), 1000)

    })

    window.LP_API.callFunction("signalGameReady")




  }, []);

  function getStates(cb) {

    AppState.vertxApi.userMeeting.getConnectedPlayers(({status, data, statusMessage}) => {
      if(status) {
        try {
          let players = {}

          data.DataArray
          .map(u => JSON.parse(u))
          .forEach(u => {
            players[u.UserID] = {
              ready: Math.random() > 0.5,
              room: Math.random() > 0 ? "lobby" : "mg1",
              tag: u.Tag
            }
          })
          cb(players)
        }
        catch(err) {
          console.log("err", err)
        }
      }
    })
  }



  return (
    <div className="FakeGame">
      <h1>fake game</h1>

      <div>
        <button onClick={() => window.LP_API.openSettings(onCloseSettings)} >Ouvrir les settings</button>
        <button onClick={() => window.LP_API.openVideo('video-test', onVideoClose)} >Ouvrir la vidéo de test</button>
        <button onClick={() => window.LP_API.toggleFullscreen()} >Toggle fullscreen</button>

        <button onClick={() => window.LP_API.callFunction('muteJitsi')} >Mute from game</button>
        <button onClick={() => window.LP_API.callFunction('unmuteJitsi')} >Unmute from game</button>


        <button onClick={() => {window.LP_API.callFunction('unmuteJitsi'); window.LP_API.callFunction('muteJitsi')}}>Unmute + mute from game</button>


        <button onClick={() => getStates((states) =>  window.LP_API.callFunction('updatePlayersState', states))} >Update player state</button>


        <button onClick={() => window.LP_API.callFunction('refreshPage')} >REFRESH PAGE</button>

        <button onClick={() => window.LP_API.endGame({elapsedTimeSec : 1240, type : 'end_timer'})} >FIN DU JEU Timeout</button>
        <button onClick={() => window.LP_API.endGame({elapsedTimeSec : 4568.3323, type : 'end_bad'})} >FIN DU JEU Défaite</button>
        <button onClick={() => window.LP_API.endGame({elapsedTimeSec : 299.3323, type : 'end_good'})} >FIN DU JEU Victoire </button>


          {!gameStarted && <h1>Game NOT started</h1>}
          {gameStarted && <h1>Game IS started</h1>}

      </div>
    </div>
  );
};

export default FakeGame;
