import { makeAutoObservable, toJS } from "mobx"
import { arrayDifference, getUrlVar } from "react-sdk/utils"
import AppState, { POPUPS } from "./AppState"

let JitsiMeetJS = window.JitsiMeetJS

export const ERRORS = {
  NO_AUDIO_INPUT: "NO_AUDIO_INPUT",
  NO_AUDIO_OUTPUT: "NO_AUDIO_OUTPUT",
  CANT_LIST_DEVICES: "CANT_LIST_DEVICES"
}

const MSG_NAMES = {
  CHANGE_MODE_MALENTENDANT: "CHANGE_MODE_MALENTENDANT",
}


class Jitsi {
  client = null
  conference = null

  // différentes props avec en clé l'id du user et en value l'objet/valeur associé
  users={} // les JitsiUser
  audioLevels={} // floats
  tracks={} // les JitsiTracks
  mutes={} // bools
  userReady={} // jitsiid=>bool
  userAbsent={} // jitsiid=>bool
  visibilities={} // jitsiid=>bool
  tags={} // vertx_id=>string    <============ ATTETNTION, C'EST LE VERTXID :::


  localTrack = null


  errors = []

  mode_malentendant = false
  // un peu a la va vite mais permet de save l'état du mute pour ne pas le réactiver
  // en cas d'annulation du mode malentendant si on était déja muted avant
  // ca sert aussi pour le mute from game
  muted_state_save = null
  muted = null
  force_muted = false


  show_permission_error = false

  devices = {
    audioinput: [],
    audiooutput: []
  }

  initialized = false

  constructor() {
    makeAutoObservable(this, {
      client: false,
      conference: false,
      localTracks: false
    })


  }

  getAvailableDevices() {
    return new Promise( (resolve, reject) => {

      let errors = []

      if(JitsiMeetJS.mediaDevices.isDeviceListAvailable()) {

        JitsiMeetJS.mediaDevices.enumerateDevices(devices => {
          // nous avons besoin d'une device audio input et un device audio output au minimum

          let inputs = devices.filter(d => d.kind === "audioinput")
          let outputs = devices.filter(d => d.kind === "audiooutput")
          // NOTE je n'utilise pas les audiooutputs car ffox ne les trouve pas ??
          let no_inputs = inputs.length === 0
          // let no_outputs = outputs.length === 0
          if(no_inputs) { reject(ERRORS.NO_AUDIO_INPUT) }
          // if(no_outputs) errors.push(ERRORS.NO_AUDIO_OUTPUT) // on ne fait pas ca car ca déconne avec firefox
          else {
            this.devices.audioinput = inputs
            this.devices.audiooutput = outputs
            resolve()
          }
        })
      }
      else {
        // errors.push("Can't list media devices")
        reject(ERRORS.CANT_LIST_DEVICES)
      }
    })
  }


  connect(pseudo, user_vertx_id, meetingCode) {
    if(AppState.nojitsi) return

    JitsiMeetJS.init()
    JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);

    var jitsi_conf_name = "conf_" + meetingCode;

    var options = {
      hosts: {
        domain: 'meet.jitsi',		// Internal domain. meet.jitsi by default (docker). may be used to infer others.
        muc: "muc.meet.jitsi",		// Session coordinator. If this is wrong, the connection fails with Strophe error
        focus: "focus.meet.jitsi",	// Video stream coordinator. If this is wrong, you won't see any video and get "Focus error"s on the console.
      },
      serviceUrl: window.CONFIG.jitsi.url + "?room=" + jitsi_conf_name.toLowerCase()

    }

    this.client = new JitsiMeetJS.JitsiConnection(null, null, options)


    let {CONNECTION_ESTABLISHED, CONNECTION_FAILED, CONNECTION_DISCONNECTED} = JitsiMeetJS.events.connection

    this.client.addEventListener( CONNECTION_ESTABLISHED, id => {
      // console.log("Connection Established", id)
      this.joinConference(jitsi_conf_name, user_vertx_id, pseudo)
    })

    this.client.addEventListener( CONNECTION_FAILED, (error_id, message) => {
      console.log("Connection Failed: ", message, "(", error_id, ")")
    })

    this.client.addEventListener( CONNECTION_DISCONNECTED, () => {
      console.log("Connection disconnected");
    })

    //Error
    // this.client.addEventListener("audioAvailabilityChanged", message => console.warn("audioAvailabilityChanged " + message))
    // this.client.addEventListener("errorOccurred", message => console.warn("errorOccurred " + message))
    // this.client.addEventListener("micError", message => console.log("micError " + message))

    this.client.connect();
  }

  joinConference(conf_name, user_vertx_id, pseudo) {
    if(!this.client) {
      console.error("The Jitsi client is not initialized")
      this.errors.push("The Jitsi client is not initialized")
      return
    }

    if(!conf_name) {
      console.error("The conference name is invalid")
      this.errors.push("The conference name is invalid")
      return
    }

    if (this.conference && this.conference.isJoined()) {
      console.error("Already in conference", this.conference.getName() )
      this.errors.push("Already in conference", this.conference.getName())
      return;
    }


    var conf_options = {
      openBridgeChannel: true,
      startAudioMuted:false,
      startVideoMuted:false
    }

    this.conference = this.client.initJitsiConference(conf_name, conf_options)

    this.conference.setLocalParticipantProperty("vertxid", user_vertx_id)

    this.conference.setDisplayName(pseudo)

    this.conference.addCommandListener("change_mode_malentendant", this.onChangeModeMalentendant.bind(this))

    let { USER_JOINED, USER_LEFT, PARTICIPANT_PROPERTY_CHANGED, CONFERENCE_JOINED, TRACK_ADDED, TRACK_REMOVED,
          TALK_WHILE_MUTED, TRACK_AUDIO_LEVEL_CHANGED, DOMINANT_SPEAKER_CHANGED, CONFERENCE_LEFT,
          TRACK_MUTE_CHANGED, MESSAGE_RECEIVED,
          CONFERENCE_ERROR, CONFERENCE_FAILED, CONFERENCE_JOIN_IN_PROGRESS, PARTICIPANT_KICKED, KICKED
        }  = JitsiMeetJS.events.conference

    this.conference.on(CONFERENCE_JOINED, () => {
      console.log("CONFERENCE_JOINED")
      // this.createLocalTracks()

      let myId = this.conference.myUserId()


      // L'api jitsi ne me permet pas de récupérer l'objet user local, juste ceux distants
      // alors je fais ca pour mimer l'objet jitsi....
      this.users = {
        ...this.users,
        [myId] : {
          _id: myId,
          _displayName: pseudo,
          isMe: true,
          _properties: {vertxid: user_vertx_id},
          getProperty: () => user_vertx_id
        }
      }


      this.visibilities[myId] = true


    })




    this.conference.on(CONFERENCE_ERROR, (a, b, c, d, e, f) => {
      console.log("CONFERENCE_ERROR", a, b, c, d, e, f)
    })
    this.conference.on(CONFERENCE_FAILED, (a, b, c, d, e, f) => {
      console.log("CONFERENCE_FAILED", a, b, c, d, e, f)
    })
    this.conference.on(CONFERENCE_JOIN_IN_PROGRESS, () => {
      console.log("CONFERENCE_JOIN_IN_PROGRESS")
    })
    this.conference.on(PARTICIPANT_KICKED, (a, b, c, d, e, f) => {
      console.log("PARTICIPANT_KICKED", a, b, c, d, e, f)
    })
    this.conference.on(KICKED, (a, b, c, d, e, f) => {
      console.log("KICKED", a, b, c, d, e, f)
    })


    this.conference.on(USER_JOINED, (id, user) => {
      // ici user.getProperty("vertxid") est undefined, on n e peut pas l'utiliser
      console.log("USER_JOINED : " + user._id + " " + user._displayName)

      // on ajoute donc le user à la liste
      this.users = {...this.users, [id] : user}
      this.visibilities[id] = true

      let itv =  setInterval(() => {
        let missing = arrayDifference(Object.keys(this.users), Object.keys(this.tracks))
        // console.log("missing", missing)
        if(missing.length > 0) {
          console.log('TRACK(s) MISSING', missing)
          missing.forEach(jid => {
            if(jid === this.conference.myUserId()) {
              this.refreshLocalTrack()
            }
            else {
              this.conference.sendCommand("REFRESH_LOCAL_TRACK", { value: jid })
            }
          })
        }
        else {
          clearInterval(itv)
          console.log('ALL TRACKS HERE !')
        }

      }, 5000)

    })

    this.conference.addCommandListener("KICK_PARTICIPANT", (data) => {
      if(this.conference.getRole() === "moderator") {
        console.log("MODERATOR KICK_PARTICIPANT ", data.value)
        this.conference.kickParticipant(data.value)
      }
    })

    this.conference.addCommandListener("REFRESH_LOCAL_TRACK", (data) => {
      if(data.value === this.conference.myUserId()){
        console.log("REFRESH_LOCAL_TRACK", data.value)
        this.refreshLocalTrack()
      }
    })

    this.conference.on(PARTICIPANT_PROPERTY_CHANGED, (user, propertyname, c, propertyvalue) => {
      console.log("PARTICIPANT_PROPERTY_CHANGED", user._id, propertyname, propertyvalue)
      // on recoit cet event pour les autres

      if(propertyname === "vertxid" && propertyvalue === user_vertx_id) {
        console.log("Same vertx_id => kick ", user._id + " (" + user_vertx_id + ")")
        // ici le user a la meme vertxid que moi => probablement un ghost, on le kick
        if(this.conference.getRole() === "moderator") {
          this.conference.kickParticipant(user._id)
        }
        else {
          this.conference.sendCommand("KICK_PARTICIPANT", { value: user._id })
        }
      }
    })



    this.conference.on(USER_LEFT, (id, user) => {
      console.log("USER_LEFT : " + user._id + " " + user._displayName)

      delete this.users[id]
      this.users = {...this.users}

    })

    this.conference.on(TRACK_ADDED, track => {
      let user_id = track.getParticipantId()
      console.log("TRACK_ADDED", user_id)
      this.tracks = {...this.tracks, [user_id]: track}
      this.mutes = {...this.mutes, [user_id]: track.isMuted()}
    })

    this.conference.on(TRACK_REMOVED, track => {
      let user_id = track.getParticipantId()
      console.log("TRACK_REMOVED", user_id)
      delete this.tracks[user_id]
      this.tracks = {...this.tracks}
    })

    this.conference.on( TRACK_AUDIO_LEVEL_CHANGED, (participantId, audioLvl) => {
      this.audioLevels = {...this.audioLevels, [participantId]: audioLvl}
		})

    this.conference.on(CONFERENCE_LEFT, () => {
      console.log("CONFERENCE_LEFT")
      this.disconnect()
    });

    this.conference.on(TRACK_MUTE_CHANGED, track => {
      let user_id = track.getParticipantId()
      this.mutes[user_id] = track.isMuted()
    })

    this.conference.on(MESSAGE_RECEIVED, (emitter_id, message) => {
      try {
        let msg = JSON.parse(message)
        if(msg.name === MSG_NAMES.CHANGE_MODE_MALENTENDANT) {
          this.onChangeModeMalentendant(msg)
        }
      } catch(err) {
        console.log("err", err)
      }
    })
    this.createLocalTracks()
    .then(() => {
      this.conference.join();
    })
  }

  createLocalTracks(options) {
    console.log("createLocalTracks init")
    return JitsiMeetJS.createLocalTracks({ devices: ['audio'], ...options})
    .then(tracks=> {
      // NOTE on considère qu'il y a une seule track audio locale (= notre flux audio)
      if (tracks && tracks.length > 0) {

        let t = tracks[0]

        this.conference.addTrack(t, 0)
        .then(() => console.log("local track added") )
        .catch((err) => console.log("error this.conference.addTrack", err) )


        this.localTrack = t

        let notmuted = !this.muted && this.mode_malentendant === false // && !this.conference.isStartAudioMuted()

        if(notmuted) {
          this.unmute()
        }
        else {
          this.mute()
          this.force_muted = true
        }

        this.initialized = true
      }
    })
    .catch(err => {
      if(err && err.name === "gum.permission_denied") {
        this.show_permission_error = true
      }
      console.log("ERROR createLocalTracks: ", err)
      this.errors.push("ERROR createLocalTracks: " + err)
    })
  }

  refreshLocalTrack() {
    if(this.localTrack) {
      this.conference.removeTrack(this.localTrack)
      .then(() => {
        this.createLocalTracks()
      })
      .catch(err => {
        console.log("refreshLocalTrack error", err)
      })
    }
    else {
      this.createLocalTracks()
    }
  }


  leaveconference() {
    if(this.conference) {
      this.conference.leave()
			this.conference = null
			this.client.xmpp.eventEmitter.removeAllListeners("xmpp.speaker_stats_received");
    }
    else {
      this.disconnect()
    }

  }


  changeModeMalentendant(val) {

    if(!this.conference) return


    const data = {
      name: MSG_NAMES.CHANGE_MODE_MALENTENDANT,
      active: val ? 1 : 0,
      activator_pseudo: AppState.vertxApi.user.getConnectedUser().pseudo,
      activator_login: AppState.vertxApi.client.GetUserLogin()
    }

    // ce message sera aussi reçu par les retardataires à la conférence
    let msg = JSON.stringify(data)
    this.conference.sendTextMessage(msg)

  }

  onChangeModeMalentendant(data) {

    let active = data.active === 1

    this.mode_malentendant = active
    if(active) {
      this.muted_state_save = this.muted
      this.mute()
    }
    else {
      if(!this.muted_state_save) this.unmute()
      this.muted_state_save = null
    }

    if(data.activator_login !== AppState.vertxApi.client.GetUserLogin()) {
      AppState.setPopup(POPUPS.MODE_MALENTENDANT_ACTIVATED, data)
    }

  }

  disconnect() {

    if (this.client) {
      this.client.disconnect()
      this.client = null
    }
  }

  unmute(called_by_game) {
    if(AppState.nojitsi) return

    // NOTE le param est a true quand c'est appelé depuis la LP_API
    if(!this.localTrack) return


    if(this.muted_state_save !== true) {
      this.localTrack.unmute().then(() => this.muted = this.localTrack.isMuted())
      this.muted = false
    }

    this.muted_state_save = null

    if(called_by_game) {
      this.force_muted = false
    }
  }

  mute(called_by_game) {
    if(AppState.nojitsi) return
    // NOTE le param est a true quand c'est appelé depuis la LP_API, ce qui permet de dire à l conférence de se mettre par défaut en mute pour les nouveaux participants

    if(!this.localTrack) return

    if(called_by_game) {
      this.muted_state_save = this.muted
      this.force_muted = true // permet de disable le bouton mute pour pas unmute à la main
    }

    if(called_by_game && AppState.alternateMute) return

    this.localTrack.mute().then(() => this.muted = this.localTrack.isMuted())

  }
  toggleMute(called_by_game){
    if(!this.localTrack) return

    if(this.muted) this.unmute(called_by_game)
    else this.mute(called_by_game)

  }

  changeAudioOutput(deviceId) {
    JitsiMeetJS.mediaDevices.setAudioOutputDevice(deviceId)
  }

  changeAudioInput(deviceId) {

    // ici il faut : supprimer la local trazck
    this.localTrack.dispose()
    .then(() => {
      // puis en créer une nouvelle avec l'id du device...
      this.createLocalTracks({micDeviceId: deviceId})
    })


    // JitsiMeetJS.mediaDevices.setAudioOutputDevice(deviceId)
  }

  updatePlayersState(state) {

    if(AppState.nojitsi) return

    try {
      // console.log("state", state)
      // 1/ récupérer mon état -> c un peu compliqué mais bon
      let myJitsiId = this.conference.myUserId()
      let me = this.users[myJitsiId]
      let myVertxId = me.getProperty("vertxid")

      let mystate = state[myVertxId]

      // petite manip différente pour les tags, on les save avec leur vertxid
      Object.keys(state)
      .forEach(vertx_id => {
        let s = state[vertx_id]
        this.tags[vertx_id] = s.tag
      })



      for (let jitsi_id in this.users) {
        let user =  this.users[jitsi_id]
        let v_id = user.getProperty("vertxid")
        let userState = state[v_id]

        if(userState === undefined) {
          // ICI le user n'est pas dans le state.
          // on va le MASQUER (pas le suppr au cas ou il revient + tard on ne veut pas le perdre)
          this.visibilities[jitsi_id] = false
        }
        else {
          this.visibilities[jitsi_id] = true
          this.userAbsent[jitsi_id] = userState.room !== mystate.room
          this.userReady[jitsi_id] = userState.ready
        }
      }

    }
    catch(err) {
      console.log("ERREUR STATE", err)
    }

  }


  refreshParticipants() {
    // appelé en cas d'erreur
    let u = this.conference.getParticipants()
    console.log("u", u)
  }
}


export default new Jitsi()