import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import AppState from '../../../js/AppState';

import Jitsi from '../../../js/Jitsi';
import Button from '../Button/Button';

import mic from '../../../images/jitsi-mic.png';

import './JitsiFooter.css';
import Texts from 'react-sdk/Texts';

const JitsiTrack = ({track}) => {
  let r = useRef(null)


  function play() {
    console.log("track calling play")

    let startPlayPromise = r.current.play()

    if (startPlayPromise) {
      startPlayPromise
      .catch(err => {
        console.log("err", err)
        if (err.name === "NotAllowedError") {
          setTimeout(play, 2000)
        }
      })
    }
  }


  useEffect(() => {
    track.attach(r.current)
    play()
    return () => {
      track.detach(r.current)
    }
  }, []);

  useEffect(() => {

    if(!AppState.alternateMute) return

    if(Jitsi.force_muted) {
      console.log("set dimmed volume")
      r.current.volume = window.CONFIG.jitsi_dimmed_volume
    }
    else {
      console.log("set normal volume")
      r.current.volume = 1
    }
  }, [Jitsi.force_muted]);

  let id = track.getParticipantId()

  let muted = Jitsi.mutes[id] || (!AppState.alternateMute && Jitsi.force_muted)



  return <audio key={id} id={id} muted={muted} ref={r}  />
}


const JitsiUser = ({user, audioLevel, muted, ready, absent, style}) => {

  let className = "JitsiUser"
  if(absent) className += " absent"
  if(audioLevel > window.CONFIG.jitsi_highlight_level) className += " highlighted"
  if(user.isMe) className += " me"

  return (
  <div style={style} className={className} title={AppState.debugMode ? user._id : ""}>
    {!user.isMe && <div className={"mini-mic-icon" + (muted ? " muted" : "")} />}
    <div className="JitsiUser-pseudo-ctnr">
      <span className="JitsiUser-pseudo">
        {user._displayName}
      </span>
      {ready && <div className="JitsiUser-checkmark" />}
    </div>
  </div>)
}

const JitsiFooter = () => {

  function connect() {
    console.log("JitsiFooter : début de la connexion")
    let user = AppState.vertxApi.user.getConnectedUser()
    let login = AppState.vertxApi.user.getClient().GetUserLogin()

    Jitsi.connect(user.pseudo, login.replace("anonymous_", ""), AppState.vertxApi.userMeeting.getMeetingId())

  }
  useEffect(() => {
    if(Jitsi.client) return
    connect()
  }, []);

  // const [tags, setTags] = useState({});

  // useEffect(() => {

  //   let j_users = Object.values(Jitsi.users)
  //   console.log("recalculate player tag")
  //   AppState.vertxApi.userMeeting.getConnectedPlayers(({status, data, statusMessage}) => {
  //     if(status) {
  //       let vertxusers = data.DataArray.map(v => JSON.parse(v))
  //       // ici on associe le tag
  //       vertxusers.forEach(v_user => {
  //         let found_j_users = j_users.filter(j_u => {
  //           return j_u.getProperty("vertxid") === v_user.UserID
  //         })


  //         setTags(t => {
  //           found_j_users.forEach(j_user => {
  //             t[j_user._id] = v_user.Tag
  //           })

  //           return t
  //         } )

  //       })


  //     } else {
  //       console.log("getConnectedPlayers error", statusMessage)
  //     }
  //   })

  // }, [Jitsi.users])

  let users = Object.values(Jitsi.users)
  users.sort((a, b) => {
    if(a.isMe && !b.isMe) return -1
    if(!a.isMe && b.isMe) return 1
    return 0
  })


  return (
    <div className="JitsiFooter">
      <Button disabled={Jitsi.force_muted} onClick={() => Jitsi.toggleMute()} className={"mute-btn" + (Jitsi.muted ? " muted" : " unmuted")}>
        <img src={mic} />
      </Button>


      <div className="jitsi-users">

        {users
        .filter((user) => {
          return Jitsi.visibilities[user._id] === true
        })
        .map((user, index) => {
          if(user) {
            let audioLevel = Jitsi.audioLevels[user._id]
            let muted = Jitsi.mutes[user._id]
            let absent = Jitsi.userAbsent[user._id]
            let ready = Jitsi.userReady[user._id]
            let vertx_id = user.getProperty("vertxid")
            let tag = Jitsi.tags[vertx_id]
            let style = {
              background: window.CONFIG.jitsi.colors[tag],
              color: window.CONFIG.jitsi.textColors[tag],
            }

            return <JitsiUser key={user._id} user={user} absent={absent} ready={ready} audioLevel={audioLevel} muted={muted} style={style} />
          }
          else {
            return <div key={"empty-user" + index} className="empty-user"></div>
          }
        })}

      </div>



      {Object.values(Jitsi.tracks)
      .filter(track => track.getType() === "audio" && !track.isLocal())
      .map(track => <JitsiTrack key={track.getParticipantId()} track={track} />)}


      {(Jitsi.mode_malentendant || Jitsi.force_muted)  && <div className="mode_malentendant_overlay"/>}

      {/* {Jitsi.errors.map((error,index) => <div className="error" key={index}>{error}</div>)} */}

      {/* <div>
        <button onClick={handleButtonClick}>button</button>
      </div> */}

      {Jitsi.show_permission_error &&
      <div className="jitsi-permission-error">
        {Texts.get("error_permission_denied")}
      </div>}
    </div>
  );
};

export default observer(JitsiFooter)
