import NoSleep from '@uriopass/nosleep.js';
import { configure, makeAutoObservable } from "mobx";
import DataLoading from 'react-sdk/DataLoading';
import Notifications from "react-sdk/Notifications/Notifications";
import ScaleManager from "react-sdk/ScaleManager";
import Texts from "react-sdk/Texts";
import LP_api from "./LP_api";

configure({
    enforceActions: "never"
})

function getUrlVar(name) {
  var url = new URL(window.location.href);
  return url.searchParams.get(name);
}


export const SCREENS = {
  LOADING : "LOADING",
  HOME : "HOME",
  LOGIN : "LOGIN",
  GAME : "GAME",
  END_GAME : "END_GAME",
  CHAT : "CHAT"
}


export const POPUPS = {
  MENTION: "MENTION",
  TRAILER: "TRAILER",
  SETTINGS: "SETTINGS",
  VIDEO: "VIDEO",
  MODE_MALENTENDANT_ACTIVATED: "MODE_MALENTENDANT_ACTIVATED"
}

class AppState {
  debugMode = false
  language = "fr"
  screen = SCREENS.LOADING

  // lp_api = null
  iframeKey = 0

  popup = null
  popup_data = null

  dialogues = null
  vertxApi = null


  globalTimer = 0
  globalTimervisible = false

  noSleep = null

  nojitsi = getUrlVar("nojitsi") !== null
  alternateMute = getUrlVar("alternateMute") !== null

  gameReady = false

  constructor() {
    this.noSleep = new NoSleep();
      this.debugMode = getUrlVar("debug")

    ScaleManager.init(720, 1280)

    /** @type LP_api */
    this.lp_api = new LP_api()

    window.LP_API = this.lp_api

    let vertxconfig = window.VertxConfig.createFromObjet(window.CONFIG.vertx)
    this.vertxApi = window.VertxApi.create(vertxconfig)


    DataLoading.getAllFiles(window.CONFIG.requiredFiles, window.CONFIG.root)

    .then(files => {
      Texts.init(files.texts, this.language)
    })
    .then(() => {
      return new Promise( (resolve, reject) => {
        this.vertxApi.user.autoConnect((res, data) => {
          if(res.status) {
            console.log("Connected to server")
            resolve()
          }
          else {
            Notifications.error("Erreur de connexion au serveur (" + res.statusMessage + ")")
            reject()
          }

        })
      })
    })
    .then(() => {
      let saved_code = this.vertxApi.gameSettings.get().meetingCode
      let saved_autoReco = this.vertxApi.gameSettings.get().autoReco
      console.log("réusing saved meeting code", saved_code)
      if(saved_autoReco && saved_code) {
        return new Promise( (resolve, reject) => {
          this.vertxApi.userMeeting.findOpenedWithCode("MiniGames", saved_code, (result) => {
            if(result.status) {
              resolve(true)
            }
            else {
              resolve(false) // ca n'a pas marché mais on va quand même au then suivant
            }
          })
        })
      }

      return false
    })
    .then((autolog_to_meeting) => {
      if(autolog_to_meeting) this.setScreen(SCREENS.GAME)
      else this.setScreen(SCREENS.HOME)
    })
    .catch(() => {

    })
    // .then(() => this.setScreen(SCREENS.GAME))


    makeAutoObservable(this, { vertxApi: false })
  }




  setScreen(id) {
    this.screen = id
  }

  setPopup(popup_id, popupdata) {

    if(popup_id) {
      this.vertxApi.analytics.navigationEnter("popup." + popup_id)
      this.popup_data = popupdata
    }
    else {
      this.vertxApi.analytics.navigationLeave("popup." + this.popup)
      this.popup_data = null
    }
    this.popup = popup_id
  }

  setLanguage(l) {
    this.language = l
    Texts.setLanguage(l)
  }

  refreshIframe() {
    this.iframeKey++
  }

}


export default new AppState()
