import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react'
import Texts from 'react-sdk/Texts';
import AppState from '../../js/AppState';
import Jitsi from '../../js/Jitsi';
import Button from '../shared/Button/Button';

import close_icon from "../../images/close-icon.png"

import './SettingsPopup.css'
import { toJS } from 'mobx';
let JitsiMeetJS = window.JitsiMeetJS


const Switch = ({initialValue, onChange}) => {
  const [checked, setChecked] = useState(initialValue);

  function handleChange(_checked) {
    setChecked(_checked)
    onChange(_checked)

  }

  return (
    <label className={'Switch' + (checked ? " checked" : "")}>
    <input type="checkbox" checked={checked} onChange={e => handleChange(e.target.checked)} />
    <span />

  </label>)
}

const SettingsPopup = () => {

  const [inputDevice, setInputDevice] = useState(null);

  const [outputDevice, setOutputDevice] = useState(JitsiMeetJS.mediaDevices.getAudioOutputDevice());


  useEffect(() => {
    if(Jitsi.initialized) {
      setInputDevice(Jitsi.localTrack.getDeviceId())
      setOutputDevice(JitsiMeetJS.mediaDevices.getAudioOutputDevice())
    }
  }, [Jitsi.initialized]);



  useEffect(() => {
    Jitsi.getAvailableDevices()

  }, []);

  function handleClose() {
    AppState.setPopup(null)
    if(AppState.lp_api.args?.onClose) AppState.lp_api.args.onClose()
  }


  function handleLangChange(e) {
    const lang = e.target.value
    AppState.setLanguage(lang)
    AppState.vertxApi.gameSettings.set({lang})
  }

  function handleChangeModeMalentendant(val) {
    console.log("e.target.checked", val)
    Jitsi.changeModeMalentendant(!val)
  }


  function handleOutputChange(e) {
    let deviceId = e.target.value
    setOutputDevice(deviceId)
    Jitsi.changeAudioOutput(deviceId)
  }

  function handleInputChange(e) {
    let deviceId = e.target.value

    setInputDevice(deviceId)
    Jitsi.changeAudioInput(deviceId)
  }

  function handleDisconnect() {
    // ici il faut : oublier le code du meeting
    AppState.vertxApi.gameSettings.set({meetingCode: null})
    window.location.reload()

  }

  return (

    <div className="SettingsPopup" >
      <div className="SettingsPopup-close">
        <Button onClick={handleClose}>
          <img src={close_icon} alt="" />
        </Button>
      </div>

      <h2>{Texts.get("settings-popup-title")}</h2>

      <div className="SettingsPopup-content" >

        <div className="Settings-block">
          <h3>{Texts.get("settings-popup-mode-malentendant")}</h3>
          <Switch onChange={handleChangeModeMalentendant} initialValue={!Jitsi.mode_malentendant} />
        </div>
        <div className="Settings-text" dangerouslySetInnerHTML={{__html: Texts.get("settings-popup-text")}} />

        {inputDevice && JitsiMeetJS.mediaDevices.isDeviceChangeAvailable("input") && Jitsi.devices.audioinput.length > 0 &&
        <div className="Settings-block">
          <h3>{Texts.get("select-audio-input")}</h3>
          <div className="select-ctnr">
            <select onChange={handleInputChange} value={inputDevice}>
              {Jitsi.devices.audioinput.map(d => <option key={d.deviceId} value={d.deviceId}>{d.label}</option>)}
            </select>
            <Button>▼</Button>
          </div>
        </div>}

        {outputDevice && JitsiMeetJS.mediaDevices.isDeviceChangeAvailable("output") && Jitsi.devices.audiooutput.length > 0 &&
        <div className="Settings-block">
          <h3>{Texts.get("select-audio-output")}</h3>
          <div className="select-ctnr">
            <select onChange={handleOutputChange} value={outputDevice}>
              {Jitsi.devices.audiooutput.map(d => <option key={d.deviceId} value={d.deviceId}>{d.label}</option>)}
            </select>
            <Button>▼</Button>
          </div>
        </div>}

        <div className="Settings-block">
          <Button onClick={handleDisconnect}>{Texts.get("disconnect")}</Button>

        </div>

      </div>



    </div>
  );
};

export default observer(SettingsPopup)
