import { observer } from 'mobx-react-lite';
import React from 'react'
import Texts from 'react-sdk/Texts';
import AppState from '../../js/AppState';
import Jitsi from '../../js/Jitsi';
import Popup from '../shared/Popup/Popup';

import './JistiModeMalentendantPopup.css'

const JistiModeMalentendantPopup = () => {
  let title = Jitsi.mode_malentendant ?  Texts.get("popup-malentendant-title-chat-desactive") : Texts.get("popup-malentendant-title-chat-active")

  return (
    <div className="ModeMalentendantPopup-overlay">
      <Popup className='JistiModeMalentendantPopup' title={title} onClose={() => AppState.setPopup(null)}>
        {Jitsi.mode_malentendant && <div dangerouslySetInnerHTML={{__html: Texts.get("popup-malentendant-content-desactive", {mustacheVars: {user: AppState.popup_data.activator_pseudo}})}}></div>}

        {!Jitsi.mode_malentendant && <div dangerouslySetInnerHTML={{__html: Texts.get("popup-malentendant-content-active", {mustacheVars: {user: AppState.popup_data.activator_pseudo}})}}></div>}
      </Popup>
    </div>
  );
};

export default  observer(JistiModeMalentendantPopup)
